export enum LanguageCodesEnum {
	AF = 'af',
	SQ = 'sq',
	AM = 'am',
	AR = 'ar',
	HY = 'hy',
	AZ = 'az',
	EU = 'eu',
	BN = 'bn',
	BS = 'bs',
	BG = 'bg',
	CA = 'ca',
	YUE = 'yue',
	ZH = 'zh',
	HR = 'hr',
	CS = 'cs',
	DA = 'da',
	NL = 'nl',
	EN = 'en',
	ET = 'et',
	FI = 'fi',
	FR = 'fr',
	GL = 'gl',
	KA = 'ka',
	DE = 'de',
	EL = 'el',
	GU = 'gu',
	HE = 'he',
	HI = 'hi',
	HU = 'hu',
	IS = 'is',
	ID = 'id',
	GA = 'ga',
	IT = 'it',
	JA = 'ja',
	KN = 'kn',
	KO = 'ko',
	KY = 'ky',
	LO = 'lo',
	LV = 'lv',
	LT = 'lt',
	MK = 'mk',
	MS = 'ms',
	ML = 'ml',
	MR = 'mr',
	MN = 'mn',
	NE = 'ne',
	NB = 'nb',
	OR = 'or',
	PL = 'pl',
	PT = 'pt',
	PA = 'pa',
	RO = 'ro',
	RU = 'ru',
	SR = 'sr', // actual code on azure 'sr-Latn'
	SK = 'sk',
	SL = 'sl',
	SO = 'so',
	ES = 'es',
	SW = 'sw',
	SV = 'sv',
	TA = 'ta',
	TE = 'te',
	TH = 'th',
	TR = 'tr',
	UK = 'uk',
	UR = 'ur',
	UZ = 'uz',
	VI = 'vi',
	CY = 'cy',
}

type TLanguages = {
	[key: string]: {
		name: string;
		availability: boolean;
		langCode: string;
		countryCode: string;
		voice: string | null;
		nativeName: string;
	};
};

export const languages: TLanguages = {
	[LanguageCodesEnum.AF]: {
		name: 'Afrikaans',
		availability: true,
		voice: 'af-ZA-Standard-A',
		langCode: 'af',
		countryCode: 'ZA',
		nativeName: 'Afrikaans',
	},
	[LanguageCodesEnum.SQ]: {
		name: 'Albanian',
		availability: true,
		voice: null,
		langCode: 'sq',
		countryCode: 'AL',
		nativeName: 'shqip',
	},
	[LanguageCodesEnum.AM]: {
		name: 'Amharic',
		availability: true,
		voice: null,
		langCode: 'am',
		countryCode: 'ET',
		nativeName: 'ኣማርኛ',
	},
	[LanguageCodesEnum.AR]: {
		name: 'Arabic',
		availability: true,
		voice: 'Hala',
		langCode: 'ar',
		countryCode: 'AE',
		nativeName: 'العربية',
	},
	[LanguageCodesEnum.HY]: {
		name: 'Armenian',
		availability: true,
		voice: null,
		langCode: 'hy',
		countryCode: 'AM',
		nativeName: 'Հայերէն',
	},
	[LanguageCodesEnum.AZ]: {
		name: 'Azerbaijani',
		availability: true,
		voice: null,
		langCode: 'az',
		countryCode: 'AZ',
		nativeName: 'Azərbaycan dili',
	},
	[LanguageCodesEnum.EU]: {
		name: 'Basque',
		availability: true,
		voice: null,
		langCode: 'eu',
		countryCode: 'ES',
		nativeName: 'euskara',
	},
	[LanguageCodesEnum.BN]: {
		name: 'Bengali',
		availability: true,
		voice: 'bn-IN-Wavenet-A',
		langCode: 'bn',
		countryCode: 'BD',
		nativeName: 'বাংলা',
	},
	[LanguageCodesEnum.BS]: {
		name: 'Bosnian',
		availability: true,
		voice: null,
		langCode: 'bs',
		countryCode: 'BA',
		nativeName: 'босански',
	},
	[LanguageCodesEnum.BG]: {
		name: 'Bulgarian',
		availability: true,
		voice: null,
		langCode: 'bg',
		countryCode: 'BG',
		nativeName: 'Български',
	},
	[LanguageCodesEnum.CA]: {
		name: 'Catalan',
		availability: true,
		voice: 'ca-ES-Standard-A',
		langCode: 'ca',
		countryCode: 'ES',
		nativeName: 'català',
	},
	[LanguageCodesEnum.YUE]: {
		name: 'Chinese (Cantonese)',
		availability: true,
		voice: 'Hiudish',
		langCode: 'yue',
		countryCode: 'CN',
		nativeName: '中文',
	},
	[LanguageCodesEnum.ZH]: {
		name: 'Chinese',
		availability: true,
		voice: 'yue-HK-Standard-A',
		langCode: 'zh-Hans',
		countryCode: 'CN',
		nativeName: '中文',
	},
	[LanguageCodesEnum.HR]: {
		name: 'Croatian',
		availability: true,
		voice: null,
		langCode: 'hr',
		countryCode: 'HR',
		nativeName: 'Hravtski',
	},
	[LanguageCodesEnum.CS]: {
		name: 'Czech',
		availability: true,
		voice: 'cs-CZ-Standard-A',
		langCode: 'cs',
		countryCode: 'CZ',
		nativeName: 'čeština',
	},
	[LanguageCodesEnum.DA]: {
		name: 'Danish',
		availability: true,
		voice: 'Sofie',
		langCode: 'da',
		countryCode: 'DK',
		nativeName: 'dansk',
	},
	[LanguageCodesEnum.NL]: {
		name: 'Dutch',
		availability: true,
		voice: 'Laura',
		langCode: 'nl',
		countryCode: 'NL',
		nativeName: 'Nederlands',
	},
	[LanguageCodesEnum.EN]: {
		name: 'English',
		availability: true,
		voice: 'Kajal',
		langCode: 'en',
		countryCode: 'IN',
		nativeName: 'English',
	},
	[LanguageCodesEnum.ET]: {
		name: 'Estonian',
		availability: true,
		voice: null,
		langCode: 'et',
		countryCode: 'EE',
		nativeName: 'eesti keel',
	},
	[LanguageCodesEnum.FI]: {
		name: 'Finnish',
		availability: true,
		voice: 'Suvi',
		langCode: 'fi',
		countryCode: 'FI',
		nativeName: 'suomi',
	},
	[LanguageCodesEnum.FR]: {
		name: 'French',
		availability: true,
		voice: 'Lèa',
		langCode: 'fr',
		countryCode: 'FR',
		nativeName: 'français',
	},
	[LanguageCodesEnum.GL]: {
		name: 'Galician',
		availability: true,
		voice: null,
		langCode: 'gl',
		countryCode: 'ES',
		nativeName: 'Gã',
	},
	[LanguageCodesEnum.KA]: {
		name: 'Georgian',
		availability: true,
		voice: null,
		langCode: 'ka',
		countryCode: 'GE',
		nativeName: 'ქართული ენა',
	},
	[LanguageCodesEnum.DE]: {
		name: 'German',
		availability: true,
		voice: 'Vicki',
		langCode: 'de',
		countryCode: 'DE',
		nativeName: 'Deutsch',
	},
	[LanguageCodesEnum.EL]: {
		name: 'Greek',
		availability: true,
		voice: 'el-GR-Standard-A',
		langCode: 'el',
		countryCode: 'GR',
		nativeName: 'ελληνικά',
	},
	[LanguageCodesEnum.GU]: {
		name: 'Gujarati',
		availability: true,
		voice: 'gu-IN-Wavenet-A',
		langCode: 'gu',
		countryCode: 'IN',
		nativeName: 'ગુજરાતી',
	},
	[LanguageCodesEnum.HE]: {
		name: 'Hebrew',
		availability: true,
		voice: null,
		langCode: 'he',
		countryCode: 'IL',
		nativeName: '[ʔivˈʁit / ʕivˈɾit]',
	},
	[LanguageCodesEnum.HI]: {
		name: 'Hindi',
		availability: true,
		voice: 'Kajal',
		langCode: 'hi',
		countryCode: 'IN',
		nativeName: 'हिंदी',
	},
	[LanguageCodesEnum.HU]: {
		name: 'Hungarian',
		availability: true,
		voice: 'hu-HU-Standard-A',
		langCode: 'hu',
		countryCode: 'HU',
		nativeName: 'Gã',
	},
	[LanguageCodesEnum.IS]: {
		name: 'Icelandic',
		availability: true,
		voice: 'is-IS-Standard-A',
		langCode: 'is',
		countryCode: 'IS',
		nativeName: 'къIaваннаб мицци',
	},
	[LanguageCodesEnum.ID]: {
		name: 'Indonesian',
		availability: true,
		voice: 'id-ID-Standard-B',
		langCode: 'id',
		countryCode: 'ID',
		nativeName: 'Bahasa Indonesia',
	},
	[LanguageCodesEnum.GA]: {
		name: 'Irish',
		availability: true,
		voice: null,
		langCode: 'ga',
		countryCode: 'IE',
		nativeName: 'Gaeilge',
	},
	[LanguageCodesEnum.IT]: {
		name: 'Italian',
		availability: true,
		voice: 'Bianco',
		langCode: 'it',
		countryCode: 'IT',
		nativeName: 'italiano',
	},
	[LanguageCodesEnum.JA]: {
		name: 'Japanese',
		availability: true,
		voice: 'Kaazuha',
		langCode: 'ja',
		countryCode: 'JP',
		nativeName: '日本語',
	},
	[LanguageCodesEnum.KN]: {
		name: 'Kannada',
		availability: true,
		voice: 'kn-IN-Standard-A',
		langCode: 'kn',
		countryCode: 'IN',
		nativeName: 'ಕನ್ನಡ',
	},
	[LanguageCodesEnum.KO]: {
		name: 'Korean',
		availability: true,
		voice: 'Seoyeon',
		langCode: 'ko',
		countryCode: 'KR',
		nativeName: '한국어 [韓國語]',
	},
	[LanguageCodesEnum.KY]: {
		name: 'Kyrgyz',
		availability: true,
		voice: null,
		langCode: 'ky',
		countryCode: 'KG',
		nativeName: 'Fuyü Gïrgïs',
	},
	[LanguageCodesEnum.LO]: {
		name: 'Lao',
		availability: true,
		voice: null,
		langCode: 'lo',
		countryCode: 'LA',
		nativeName: 'ພາສາລາວ',
	},
	[LanguageCodesEnum.LV]: {
		name: 'Latvian',
		availability: true,
		voice: 'lv-LV-Standard-A',
		langCode: 'lv',
		countryCode: 'LV',
		nativeName: 'latviešu valoda',
	},
	[LanguageCodesEnum.LT]: {
		name: 'Lithuanian',
		availability: true,
		voice: null,
		langCode: 'lt',
		countryCode: 'LT',
		nativeName: 'lietuvių kalba',
	},
	[LanguageCodesEnum.MK]: {
		name: 'Macedonian',
		availability: true,
		voice: null,
		langCode: 'mk',
		countryCode: 'MK',
		nativeName: 'Ẹ̀dó',
	},
	[LanguageCodesEnum.MS]: {
		name: 'Malay',
		availability: true,
		voice: 'ms-MY-Standard-A',
		langCode: 'ms',
		countryCode: 'MY',
		nativeName: 'بهاس ملايو',
	},
	[LanguageCodesEnum.ML]: {
		name: 'Malayalam',
		availability: true,
		voice: 'ml-IN-Standard-A',
		langCode: 'ml',
		countryCode: 'IN',
		nativeName: 'മലയാളം',
	},
	[LanguageCodesEnum.MR]: {
		name: 'Marathi',
		availability: true,
		voice: 'mr-IN-Wavenet-A',
		langCode: 'mr',
		countryCode: 'IN',
		nativeName: 'मराठी',
	},
	[LanguageCodesEnum.MN]: {
		name: 'Mongolian',
		availability: true,
		voice: null,
		langCode: 'mn',
		countryCode: 'MN',
		nativeName: 'ဘာသာ မန်',
	},
	[LanguageCodesEnum.NE]: {
		name: 'Nepali',
		availability: true,
		voice: null,
		langCode: 'ne',
		countryCode: 'NP',
		nativeName: 'नेपाली',
	},
	[LanguageCodesEnum.NB]: {
		name: 'Norwegian',
		availability: true,
		voice: 'Ida',
		langCode: 'nb',
		countryCode: 'NO',
		nativeName: 'norsk',
	},
	[LanguageCodesEnum.OR]: {
		name: 'Odia',
		availability: false,
		voice: null,
		langCode: 'or',
		countryCode: 'IN',
		nativeName: 'ଓଡ଼ିଆ',
	},
	[LanguageCodesEnum.PL]: {
		name: 'Polish',
		availability: true,
		voice: 'Ola',
		langCode: 'pl',
		countryCode: 'PL',
		nativeName: 'polski',
	},
	[LanguageCodesEnum.PT]: {
		name: 'Portuguese',
		availability: true,
		voice: 'Inês',
		langCode: 'pt',
		countryCode: 'PT',
		nativeName: 'Português',
	},
	[LanguageCodesEnum.PA]: {
		name: 'Punjabi',
		availability: true,
		voice: 'pa-IN-Standard-A',
		langCode: 'pa',
		countryCode: 'IN',
		nativeName: 'ਪੰਜਾਬੀ',
	},
	[LanguageCodesEnum.RO]: {
		name: 'Romanian',
		availability: true,
		voice: 'ro-RO-Standard-A',
		langCode: 'ro',
		countryCode: 'RO',
		nativeName: 'Armâneaşti',
	},
	[LanguageCodesEnum.RU]: {
		name: 'Russian',
		availability: true,
		voice: 'ru-RU-Standard-A',
		langCode: 'ru',
		countryCode: 'RU',
		nativeName: 'Русский язык',
	},
	[LanguageCodesEnum.SR]: {
		name: 'Serbian',
		availability: true,
		voice: 'sr-rs-Standard-A',
		langCode: 'sr',
		countryCode: 'RS',
		nativeName: 'српски',
	},
	[LanguageCodesEnum.SK]: {
		name: 'Slovak',
		availability: true,
		voice: 'sk-SK-Standard-A',
		langCode: 'sk',
		countryCode: 'SK',
		nativeName: 'slovenčina',
	},
	[LanguageCodesEnum.SL]: {
		name: 'Slovenian',
		availability: true,
		voice: null,
		langCode: 'sl',
		countryCode: 'SI',
		nativeName: 'slovenščina',
	},
	[LanguageCodesEnum.SO]: {
		name: 'Somali',
		availability: true,
		voice: null,
		langCode: 'so',
		countryCode: 'SO',
		nativeName: 'Mali',
	},
	[LanguageCodesEnum.ES]: {
		name: 'Spanish',
		availability: true,
		voice: 'Lucia',
		langCode: 'es',
		countryCode: 'ES',
		nativeName: 'español',
	},
	[LanguageCodesEnum.SW]: {
		name: 'Swahili',
		availability: true,
		voice: null,
		langCode: 'sw',
		countryCode: 'ID',
		nativeName: 'kiSwahili',
	},
	[LanguageCodesEnum.SV]: {
		name: 'Swedish',
		availability: true,
		voice: 'Elin',
		langCode: 'sv',
		countryCode: 'SE',
		nativeName: 'svenska',
	},
	[LanguageCodesEnum.TA]: {
		name: 'Tamil',
		availability: true,
		voice: 'ta-IN-Standard-A',
		langCode: 'ta',
		countryCode: 'IN',
		nativeName: 'தமிழ்',
	},
	[LanguageCodesEnum.TE]: {
		name: 'Telugu',
		availability: true,
		voice: 'te-IN-Standard-A',
		langCode: 'te',
		countryCode: 'IN',
		nativeName: 'తెలుగు',
	},
	[LanguageCodesEnum.TH]: {
		name: 'Thai',
		availability: true,
		voice: null,
		langCode: 'th',
		countryCode: 'TH',
		nativeName: 'คำเมือง',
	},
	[LanguageCodesEnum.TR]: {
		name: 'Turkish',
		availability: true,
		voice: 'tr-TR-Standard-A',
		langCode: 'tr',
		countryCode: 'TR',
		nativeName: 'Türkçe',
	},
	[LanguageCodesEnum.UK]: {
		name: 'Ukrainian',
		availability: true,
		voice: 'uk-UA-Standard-A',
		langCode: 'uk',
		countryCode: 'UA',
		nativeName: 'українська мова',
	},
	[LanguageCodesEnum.UR]: {
		name: 'Urdu',
		availability: true,
		voice: null,
		langCode: 'ur',
		countryCode: 'PK',
		nativeName: 'اُردُو',
	},
	[LanguageCodesEnum.UZ]: {
		name: 'Uzbek',
		availability: true,
		voice: null,
		langCode: 'uz',
		countryCode: 'UZ',
		nativeName: 'Ўзбек тили',
	},
	[LanguageCodesEnum.VI]: {
		name: 'Vietnamese',
		availability: true,
		voice: 'vi-VN-Standard-A',
		langCode: 'vi',
		countryCode: 'VN',
		nativeName: 'Tiếng Việt',
	},
	[LanguageCodesEnum.CY]: {
		name: 'Welsh',
		availability: true,
		voice: null,
		langCode: 'cy',
		countryCode: 'GB',
		nativeName: 'Cymraeg',
	},
};

export const languageOptions = [
	{
		label: languages[LanguageCodesEnum.HI].name,
		value: LanguageCodesEnum.HI,
	},
	{
		label: languages[LanguageCodesEnum.TA].name,
		value: LanguageCodesEnum.TA,
	},
	{
		label: languages[LanguageCodesEnum.TE].name,
		value: LanguageCodesEnum.TE,
	},
	{
		label: languages[LanguageCodesEnum.ML].name,
		value: LanguageCodesEnum.ML,
	},
	{
		label: languages[LanguageCodesEnum.KN].name,
		value: LanguageCodesEnum.KN,
	},
	{
		label: languages[LanguageCodesEnum.BN].name,
		value: LanguageCodesEnum.BN,
	},
	{
		label: languages[LanguageCodesEnum.MR].name,
		value: LanguageCodesEnum.MR,
	},
];

export const questionLanguageOptions = [
	{
		label: languages[LanguageCodesEnum.EN].name,
		value: LanguageCodesEnum.EN,
	},
	...languageOptions,
];

export const audioLanguageOptions = [
	{
		label: 'Hindi Custom',
		value: LanguageCodesEnum.HI,
	},
	{
		label: 'Tamil Custom',
		value: LanguageCodesEnum.TA,
	},
	{
		label: 'Telugu Custom',
		value: LanguageCodesEnum.TE,
	},
	{
		label: 'Malayalam Custom',
		value: LanguageCodesEnum.ML,
	},
	{
		label: 'Kannada Custom',
		value: LanguageCodesEnum.KN,
	},
	{
		label: 'Bengali Custom',
		value: LanguageCodesEnum.BN,
	},
	{
		label: 'Marathi Custom',
		value: LanguageCodesEnum.MR,
	},
];
