export const slimBorder = '1px solid #ccc';
export const LessonAdminUiStateEnum = {
	EDIT_DISABLED: 'EDIT_DISABLED',
	COPIED_LESSON: 'COPIED_LESSON',
	REFLECTION: 'REFLECTION',
	NO_COPY_ALLOWED: 'NO_COPY_ALLOWED',
};
export const lightBackgroundColors = [
	'#FFEBB2', // Light Yellow
	'#C5E1A5', // Light Green
	'#AEDFF7', // Light Blue
	'#F8C8DC', // Light Pink
	'#FFDAB9', // Peach
	'#D7BDE2', // Lavender
	'#B2EBF2', // Aqua
	'#F5DEB3', // Wheat
	'#FFCC80', // Light Orange
	'#D4E157', // Lime
	'#FFF59D', // Soft Gold
	'#F0F8FF', // Alice Blue
	'#FAEBD7', // Antique White
	'#F5F5DC', // Beige
	'#E0FFFF', // Light Cyan
	'#F0FFF0', // Honeydew
	'#FFF0F5', // Lavender Blush
	'#F5F5F5', // White Smoke
	'#FFFACD', // Lemon Chiffon
	'#E6E6FA', // Lavender
	'#FFFAF0', // Floral White
	'#F0E68C', // Khaki
	'#E1F0C4', // Pale Green
	'#FFF8E7', // Light Cream
	'#E6F2FF', // Lightest Blue
	'#FFFAFA', // Ghost White
	'#F2E6FF', // Light Periwinkle
	'#D0FFD0', // Light Mint
	'#FFFFE0', // Light Yellow
	'#B9F6CA', // Light Mint Green
	'#FFECB3', // Light Amber
	'#BBDEFB', // Light Steel Blue
	'#FFCCBC', // Light Coral
	'#CE93D8', // Light Violet
	'#FAF9F6', // Off-White
	'#F8F8FF', // Light Gainsboro
	'#EEEEEE', // Light Gray
	'#DEB887', // BurlyWood
	'#CD853F', // Peru
];

export enum UiTypeEnum {
	READING = 'READING',
	ROLEPLAY = 'ROLEPLAY',
	GRAMMAR = 'GRAMMAR',
	VOCAB = 'VOCAB',
	CALL = 'CALL',
	SPEAK = 'SPEAK',
}

export const LessonUiTypeOptions = [
	{ value: UiTypeEnum.READING, label: 'Reading' },
	{ value: UiTypeEnum.ROLEPLAY, label: 'Roleplay' },
	{ value: UiTypeEnum.GRAMMAR, label: 'Grammar' },
	{ value: UiTypeEnum.VOCAB, label: 'Vocabulary' },
	{ value: UiTypeEnum.CALL, label: 'Call' },
	{ value: UiTypeEnum.SPEAK, label: 'Speak' },
];
